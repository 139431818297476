import { graphql } from "gatsby";
import SEO from "@components/seo";
import React from "react";
import MdPin from "react-ionicons/lib/IosPinOutline";
import { css } from "@emotion/react";
import PrevNextNav from "@components/PrevNextNav";
import { PostThumbnail } from "@components/PostThumbnail";
import { Text } from "@components/Typography";
import { renderModularContent } from "@components/renderBlogPost";
import KarriereHeader from "@components/KarriereHeader";
import { Section, Div, Container, RowsNine } from "@components/Markup";
import Share from "@components/Share";

export const KarriereLocation = () => (
  <Div
    flex
    ai="center"
    css={css`
      opacity: 0.5;
      margin-left: -3px;
    `}
  >
    <MdPin color={"black"} fontSize={"20px"} />
    <Text
      css={css`
        font-size: 14px !important;
        margin-left: 6px;
      `}
    >
      Wien, Austria
    </Text>
  </Div>
);

const Karriere = ({ data }) => {
  const { karriere, nextPost, prevPost } = data;
  const { content, postTitle, postDate, featuredImage, excerpt } = karriere;

  return (
    <React.Fragment>
      <SEO
        title={postTitle}
        image={featuredImage?.fluid?.src}
        description={excerpt}
      />
      <Section pt="lg" pb="lg">
        <Container>
          <RowsNine>
            <KarriereHeader postTitle={postTitle} postDate={postDate} />
            <Div
              css={css`
                position: relative;
              `}
            >
              <Share />
              <PostThumbnail featuredImage={featuredImage} />
            </Div>
            <Div mt="md" mb="md">
              {renderModularContent({ content })}
            </Div>
          </RowsNine>
        </Container>
        {prevPost && nextPost && (
          <PrevNextNav
            prev={{
              featuredImage: prevPost.featuredImage,
              name: prevPost.postTitle,
              url: `/karriere/${prevPost.slug}`,
            }}
            next={{
              featuredImage: nextPost.featuredImage,
              name: nextPost.postTitle,
              url: `/karriere/${nextPost.slug}`,
            }}
          />
        )}
      </Section>
    </React.Fragment>
  );
};

export default Karriere;

export const query = graphql`
  query KarrierePageQuery(
    $slug: String!
    $nextSlug: String!
    $prevSlug: String!
  ) {
    nextPost: datoCmsKarriere(slug: { eq: $nextSlug }) {
      slug
      postTitle
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    prevPost: datoCmsKarriere(slug: { eq: $prevSlug }) {
      slug
      postTitle
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    karriere: datoCmsKarriere(slug: { eq: $slug }) {
      slug
      postTitle
      postDate
      excerpt
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      content {
        ... on DatoCmsImage {
          id
          items {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
        ... on DatoCmsQuote {
          id
          text
          __typename
        }
        ... on DatoCmsParagraph {
          id
          text
          __typename
        }
      }
    }
  }
`;
